import { Request } from '@/http/request'

// 添加接口价格
export function addPrice(parameter: any){
  return Request.axiosInstance.post('/open/interface-price/add', parameter)
}

// 删除接口价格
export function deletePrice(parameter: any){
  return Request.axiosInstance.post('/open/interface-price/delete', parameter)
}

// 修改接口价格
export function editPrice(parameter: any){
  return Request.axiosInstance.post('/open/interface-price/edit', parameter)
}

// 接口价格列表
export function listPrice(parameter: any) {
  return Request.axiosInstance.post('/open/interface-price/list', parameter)
}

// 接口价格充值
export function addBalance(parameter: any) {
  return Request.axiosInstance.post('/open/interface/add-interface-balance', parameter)
}

// 接口价格扣费
export function decBalance(parameter: any) {
  return Request.axiosInstance.post('/open/interface/dec-interface-balance', parameter)
}

export default { addPrice,deletePrice,editPrice,listPrice,addBalance,decBalance }