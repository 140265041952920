<template>
  <!-- 接口管理页面 -->
  <div class="Interface">
    <!-- 筛选区域 -->
    <div class="screening">
      <!-- 筛选查询弹窗 -->
      <div>
        <el-dialog title="查询" v-model="theQueryDialog" :close-on-click-modal="false" :destroy-on-close="true"
          width="30%">
          <div class="">
            <el-form label-position="left" label-width="100px" size="small" :model="searchForm" ref="upform">
              <div class="form-out-box p-0-30">
                <el-form-item label="服务商：">
                  <el-select v-model="search_Form.amountId" filterable placeholder="请选择" style="width: 100%" clearable>
                    <el-option v-for="(item, index) in amount_id" :key="index" :label="item.name" :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="接口名称：">
                  <el-input v-model="search_Form.name" clearable placeholder="请填写接口名称"></el-input>
                </el-form-item>
                <el-form-item label="接口状态：">
                  <el-select v-model="search_Form.status" placeholder="请选择" style="width: 100%" clearable size="mini">
                    <el-option v-for="(item, index) in ['正常', '禁用']" :key="index" :label="item" :value="index">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="创建日期：">
                  <el-date-picker v-model="valueTimeCreate" type="datetimerange" start-placeholder="开始日期"
                    end-placeholder="结束日期" style="width: 100%" value-format="YYYY-MM-DD HH:mm:ss"
                    :default-time="defaultTime" size="mini">
                  </el-date-picker>
                </el-form-item>
              </div>
              <el-form-item>
                <div class="flex-nowrap-flex-end">
                  <el-button class="cancel" plain @click="searchTapReset" round>
                    重置
                  </el-button>
                  <el-button class="confirmAdd" @click="searchTapFun" round>
                    查询
                  </el-button>
                </div>
              </el-form-item>
            </el-form>
          </div>
        </el-dialog>
      </div>
    </div>
    <!-- 操作区域 -->
    <el-row>
      <el-col :span="12">
        <div class="title-name">接口列表</div>
      </el-col>
      <el-col :span="12">
        <div class="title-button">
          <div class="ml-10">
            <el-button icon="el-icon-search" class="button-el" size="medium" @click="theQueryDialog=true">查询</el-button>
          </div>
          <div class="ml-10">
            <el-button class="button-el" @click="addOrModify(0)" size="medium">
              <icon-font type="icon-tianjia1" class="button-icon-font" />
              新增接口
            </el-button>
          </div>
          <div class="ml-10">
            <el-tooltip content="刷新列表" placement="top" effect="light">
              <div class="font-refresh-out flex-nowrap-center-center" @click="searchTapReset(1)">
                <icon-font v-if="loading==false" type="icon-shuaxin" class="font-refresh" />
                <i v-else class="el-icon-loading loding-color"></i>
              </div>
            </el-tooltip>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 数据列表 -->
    <el-row>
      <el-col>
        <div>
          <el-table v-loading="loading" ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
            size="mini" max-height="670" :row-style="{height:'50px'}" :cell-style="{padding:'0px'}"
            :header-cell-style="{ background: '#e0e5ff', color: '#24252F' }">
            <!-- 公共下拉选择 -->
            <el-table-column type="expand" fixed>
              <template #default="scope">
                <div v-if="evalJSON(scope.row)">
                  <a-descriptions bordered :column="1" size="small">
                    <a-descriptions-item v-for="(value, key) in evalJSON(scope.row)" :key="key" :label="key">
                      {{ value }}
                    </a-descriptions-item>
                  </a-descriptions>
                </div>
                <div v-else>
                  该接口无配置信息
                </div>
              </template>
            </el-table-column>
            <el-table-column label="服务商" width="180" fixed>
              <template #default="scope">
                {{ amountMap[scope.row.amountId] }}
              </template>
            </el-table-column>
            <el-table-column label="接口名称" width="180" fixed>
              <template #default="scope">{{
                scope.row.name == null ? "-" : scope.row.name
              }}</template>
            </el-table-column>
            <el-table-column label="备注" width="200">
              <template #default="scope">{{
                scope.row.remark == null ? "-" : scope.row.remark
              }}</template>
            </el-table-column>
            <el-table-column label="添加时间" width="200">
              <template #default="scope">{{
                scope.row.createTime == null ? "-" : scope.row.createTime
              }}</template>
            </el-table-column>
            <!-- <el-table-column label="更新时间" width="160px">
              <template #default="scope">{{
                scope.row.updateTime == null ? "-" : scope.row.updateTime
              }}</template>
            </el-table-column> -->
            <el-table-column label="接口状态">
              <template #default="scope">
                <span
                  :class="scope.row.status == 0?'pass':'unpass'">{{scope.row.status == null ? "-" : scope.row.statusText}}</span></template>
            </el-table-column>
            <el-table-column label="接口实现类名" width="140">
              <template #default="scope">{{
                scope.row.beanName == null ? "-" : scope.row.beanName
              }}</template>
            </el-table-column>
            <el-table-column label="接口余额" width="120">
              <template #default="scope">
                <span class="no-red">
                  ￥{{ scope.row.balance == null ? "-" : scope.row.balance }}
                </span>
              </template>
            </el-table-column>
            <el-table-column label="服务商价格(价格|成本)" width="600">
              <template #default="scope">
                <label v-for="(item, index) in scope.row.interfacePrice" :key="index">
                  <a-tag>
                    <a-space>
                      <div>
                        {{ typeName(item.type) }}
                        {{ item.price }} | {{ item.costPrice }}
                      </div>
                      <i @click="addOrModify(3,item)" class="el-icon-edit pointer"></i>
                      <el-popconfirm title="确定删除吗？" @confirm="deleteLabel(item.id)">
                        <template #reference>
                          <i class="el-icon-delete pointer"></i>
                        </template>
                      </el-popconfirm>
                    </a-space>
                  </a-tag>
                </label>
                <el-tooltip v-if="scope.row.interfacePrice.length < 4" content="添加接口价格" placement="top" effect="light">
                  <el-button class="editor" size="mini" icon="el-icon-plus" @click="addOrModify(2,scope.row)" circle
                    style="border: none;"></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
            <!-- 编辑用户 -->
            <el-table-column label="" width="80" fixed="right">
              <template #default="scope">
                <div class="flex-nowrap-space-around">
                  <span class="button-font-color" @click="debugging(scope.row)" v-if="scope.row.debugging != null">
                    <icon-font type="icon-tiaoshi-copy" />
                    调试
                    <span v-if="scope.row.debugging == 1" class="no-red">(熔断中)</span>
                  </span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="操作" fixed="right" width="220">
              <template #default="scope">
                <div class="flex-nowrap-space-around">
                  <span class="button-font-color" @click="copyAndDeduction(scope.row)">
                    <icon-font type="icon-bianji" />
                    复制
                  </span>
                  <span class="button-font-color" @click="rechargeAndDeduction(0,scope.row)">
                    <icon-font type="icon-bianji" />
                    扣费
                  </span>
                  <span class="button-font-color" @click="rechargeAndDeduction(1,scope.row)">
                    <icon-font type="icon-bianji" />
                    充值
                  </span>
                  <span class="button-font-color" @click="addOrModify(1, scope.row)">
                    <icon-font type="icon-bianji" />
                    编辑
                  </span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
    <!-- 页码 -->
    <el-row>
      <el-col :span="14"></el-col>
      <el-col :span="8">
        <div>
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="currentPage" background :page-sizes="[20, 50, 100, 200]" :page-size="search_Form.pageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div>
      </el-col>
    </el-row>
    <!-- 弹窗→新增或修改 -->
    <div class="popup-view">
      <el-form label-position="left" label-width="110px" :model="searchForm" :rules="rules" ref="searchForms">
        <el-dialog :title="operationType === 0?'新增接口':'修改接口'" v-model="centerDialogVisible" width="30%"
          :close-on-click-modal="false" :destroy-on-close="true">
          <div class="center-body">
            <el-form-item label="接口名称：" prop="name">
              <el-input v-model="searchForm.name" clearable placeholder="请填写接口名称"></el-input>
            </el-form-item>
            <el-form-item label="服务商：" prop="amountId">
              <el-select v-model="searchForm.amountId" placeholder="请选择" style="width: 100%" clearable
                :disabled="operationType === 0?false:true">
                <el-option v-for="(item, index) in amount_id" :key="index" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="接口状态：" prop="status">
              <el-select v-model="searchForm.status" placeholder="请选择" style="width: 100%" clearable>
                <el-option v-for="(item, index) in ['正常', '禁用']" :key="index" :label="item" :value="index">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="接口实现类型：" prop="beanName">
              <el-input v-model="searchForm.beanName" placeholder="请填写接口实现类型" clearable></el-input>
            </el-form-item>
            <!-- <el-form-item v-if="searchForm.beanName=='jiuHuaHB'" label="选择渠道用户：" prop="uids">
              <el-select v-model="searchForm.uids" style="width: 100%" filterable multiple clearable>
                <el-option v-for="(item, index) in user_list" :key="index" :label="item.company" :value="item.uid">
                </el-option>
              </el-select>
            </el-form-item> -->
            <!-- 移动回拨6 -->
            <el-form-item v-if="searchForm.beanName=='yhuatong'" label="行业类型：" prop="industry">
              <el-input v-model="searchForm.industry" :disabled="searchForm.status==1?true:false" placeholder="请输入行业类型"
                clearable></el-input>
            </el-form-item>
            <!-- 移动回拨7- -->
            <el-form-item v-if="searchForm.beanName=='huaWeiGSHB'" label="accessKey:" prop="accessKey">
              <el-input v-model="searchForm.accessKey" :disabled="searchForm.status==1?true:false" placeholder="请输入行业类型"
                clearable></el-input>
            </el-form-item>
            <!-- 移动回拨7- -->
            <el-form-item v-if="searchForm.beanName=='huaWeiGSHB'" label="secretKey:" prop="secretKey">
              <el-input v-model="searchForm.secretKey" :disabled="searchForm.status==1?true:false" placeholder="请输入用户密码"
                clearable></el-input>
            </el-form-item>
            <!-- 移动回拨7- -->
            <el-form-item v-if="searchForm.beanName=='huaWeiGSHB'" label="appKey:" prop="appKey">
              <el-input v-model="searchForm.appKey" :disabled="searchForm.status==1?true:false" placeholder="请输入用户秘钥"
                clearable></el-input>
            </el-form-item>
            <!-- 025江苏回拨 -->
            <el-form-item v-if="searchForm.beanName=='haoWaiHB'" label="appkey:" prop="appkey">
              <el-input v-model="searchForm.appkey" :disabled="searchForm.status==1?true:false" placeholder="请输入用户秘钥"
                clearable></el-input>
            </el-form-item>
            <!-- 025江苏回拨 -->
            <el-form-item v-if="searchForm.beanName=='haoWaiHB'" label="appSecret:" prop="appSecret">
              <el-input v-model="searchForm.appSecret" :disabled="searchForm.status==1?true:false" placeholder="请输入用户密码"
                clearable></el-input>
            </el-form-item>
            <!-- 中国联通7- -->
            <el-form-item v-if="searchForm.beanName=='jiuHuaHB'||searchForm.beanName=='unicomAXB'" label="用户ID："
              prop="userId">
              <el-input v-model="searchForm.userId" :disabled="searchForm.status==1?true:false" placeholder="请输入用户ID"
                clearable></el-input>
            </el-form-item>
            <!-- 中国联通7- -->
            <el-form-item v-if="searchForm.beanName=='jiuHuaHB'||searchForm.beanName=='unicomAXB'" label="秘钥："
              prop="secreteKey">
              <el-input v-model="searchForm.secreteKey" :disabled="searchForm.status==1?true:false" placeholder="请输入秘钥"
                clearable></el-input>
            </el-form-item>
            <el-form-item label="接口设置：" prop="options">
              <el-input v-model="searchForm.options" type="textarea" placeholder="请填写接口设置"
                :autosize="{ minRows: 2, maxRows: 6 }" />
            </el-form-item>
            <el-form-item label="备注：" prop="">
              <el-input v-model="searchForm.remark" type="textarea" placeholder="请填写备注"
                :autosize="{ minRows: 2, maxRows: 3 }">
              </el-input>
            </el-form-item>
            <div v-if="searchForm.beanName=='jiuHuaHB'||searchForm.beanName=='unicomAXB'" :style="{color:'#E10602'}">
              说明:点击确认保存后,会将接口账户的价格,自动同步到所选渠道用户的价格设置中;若用户没有设置资费,会新添加山东回拨线路的资费,新添加的资费与接口账户的价格相同</div>
          </div>
          <template #footer>
            <el-form-item>
              <el-button class="cancel" plain @click="centerDialogVisible = false" round>取消</el-button>
              <el-button class="confirmAdd" @click="formSubmit(operationType,'searchForms')" round>
                {{ operationType === 0 ? "新增" : "修改" }}</el-button>
            </el-form-item>
          </template>
        </el-dialog>
      </el-form>
    </div>
    <!-- 弹窗→接口价格 -->
    <div class="popup-view">
      <el-form label-position="left" label-width="100px" :model="price_info" ref="price_info" :rules="rules">
        <el-dialog title="价格设置" v-model="centerPriceVisible" width="30%" :close-on-click-modal="false"
          :destroy-on-close="true">
          <div class="center-body">
            <!-- <el-row :gutter="12">
              <el-col :span="8" v-show="operationType=='2'"> -->
            <el-form-item label="接口类型：" prop="name">
              <el-input v-model="price_info.name" disabled></el-input>
            </el-form-item>
            <!-- </el-col>
              <el-col :span="8"> -->
            <el-form-item label="价格类型：" prop="type">
              <el-select v-model="price_info.type" placeholder="请选择" style="width: 100%" clearable>
                <el-option v-for="(item, index) in ['月租','单计费','双计费','录音']" :key="index" :label="item" :value="index">
                </el-option>
              </el-select>
            </el-form-item>
            <!-- </el-col>
              <el-col :span="8"> -->
            <el-form-item label="价格：" prop="price">
              <el-input v-model="price_info.price"></el-input>
            </el-form-item>
            <!-- </el-col>
              <el-col :span="8"> -->
            <el-form-item label="成本价格：" prop="costPrice">
              <el-input v-model="price_info.costPrice"></el-input>
            </el-form-item>
            <!-- </el-col> -->
            <!-- <el-col v-show="operationType===3" :span="8">
                <el-form-item label="接口状态：" prop="">
                  <el-select
                    v-model="price_info.status"
                    placeholder="请选择"
                    style="width: 100%"
                    clearable
                  >
                    <el-option
                      v-for="(item, index) in ['正常', '禁用']"
                      :key="index"
                      :label="item"
                      :value="index"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col> -->
            <!-- </el-row> -->
          </div>
          <template #footer>
            <el-form-item>
              <el-button class="cancel" plain @click="centerPriceVisible = false" round>取消</el-button>
              <el-button class="confirmAdd" @click="formSubmit(operationType,'price_info')" round>
                {{ operationType ===2 ? "新增" : "修改" }}</el-button>
            </el-form-item>
          </template>
        </el-dialog>
      </el-form>
    </div>
    <!-- 线路调试模态框 -->
    <div>
      <el-dialog title="线路调试" v-model="debuggingPriceVisible" width="300px" :close-on-click-modal="false"
        :destroy-on-close="true">
        <div v-loading="debuggingloading" class="font-center">{{ debuggingForm.message }}</div>
        <template #footer>
          <div class="flex-nowrap-space-around">
            <el-button class="confirmAdd" plain round @click="debuggingThis">调试</el-button>
            <!-- <el-button
                class="no-red-bg"
                plain
                round
                v-if="debuggingRow.debugging == 0"
                @click="fusingOrRestore"
                >熔断</el-button> -->
            <el-button class="yes-green-bg" plain round v-if="debuggingRow.debugging == 1" @click="fusingOrRestore">恢复
            </el-button>
            <el-button class="cancel" @click="debuggingPriceVisible = false" plain round>取消</el-button>
          </div>
        </template>
      </el-dialog>
    </div>
    <!-- 弹窗充值 -->
    <div>
      <el-form label-position="left" label-width="130px" :model="amountUpform" :rules="rules" ref="amountUpform">
        <el-dialog :title="RADdata.type == 1?'余额充值':'余额扣费'" v-model="showRechargeAndDeduction" :destroy-on-close="true"
          :close-on-click-modal="false" width="30%">
          <div class="center-body">
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item label="充值接口：">
                  <div>
                    {{ RADdata.row.name }}
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item label="当前余额：">
                  <div>
                    ￥{{ RADdata.row.balance }}
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item :label="RADdata.type == 1?'充值金额(元)：':'扣费金额(元)：'" prop="amount">
                  <el-input v-model="amountUpform.amount" :placeholder="RADdata.type == 1?'请输入充值的金额':'请输入扣费的金额'"
                    oninput="value=value.replace(/[^0-9.]/g,'')" size="small">
                    <template #append>￥</template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <!-- <el-row :gutter="20">
                      <el-col :span="24">
                          <el-form-item label="备注">
                              <el-input
                                  v-model="upform.remark"
                                  size="small"
                                  :autosize="{ minRows: 2, maxRows: 4 }"
                                  type="textarea"
                                  placeholder="请输入备注"
                              ></el-input>
                          </el-form-item>
                      </el-col>
                  </el-row> -->
          </div>
          <template #footer>
            <el-form-item>
              <el-button class="cancel button-width" plain @click="resetForm('amountUpform')" size="medium"> 取消
              </el-button>
              <el-button class="confirmAdd button-width" @click="addBalance('amountUpform')" size="medium"
                v-if="RADdata.type == 1">充值</el-button>
              <el-button class="confirmAdd button-width" @click="decBalance('amountUpform')" size="medium"
                v-if="RADdata.type == 0">扣费</el-button>
            </el-form-item>
          </template>
        </el-dialog>
      </el-form>
    </div>
    <!-- 复制模态框 -->
    <div class="popup-view">
      <el-form label-position="left" label-width="110px" :model="copyFormData" :rules="rules" ref="copyform">
        <el-dialog title="复制" v-model="copyDialogVisible" width="30%" :close-on-click-modal="false"
          :destroy-on-close="true">
          <div class="center-body">
            <el-form-item label="接口名称：" prop="name">
              <el-input v-model="copyFormData.name" clearable></el-input>
            </el-form-item>
            <el-form-item label="服务商：" prop="amountId">
              <el-select v-model="copyFormData.amountId" style="width: 100%" clearable>
                <el-option v-for="(item, index) in amount_id" :key="index" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="接口状态：" prop="status">
              <el-select v-model="copyFormData.status" style="width: 100%" clearable>
                <el-option v-for="(item, index) in ['正常', '禁用']" :key="index" :label="item" :value="index">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="接口实现类型：" prop="beanName">
              <el-input v-model="copyFormData.beanName" placeholder="请填写接口实现类型" clearable></el-input>
            </el-form-item>
            <!-- <el-form-item v-if="copyFormData.beanName=='jiuHuaHB'" label="选择渠道用户：" prop="uids">
              <el-select v-model="copyFormData.uids" style="width: 100%" filterable multiple clearable>
                <el-option v-for="(item, index) in user_list" :key="index" :label="item.company" :value="item.uid">
                </el-option>
              </el-select>
            </el-form-item> -->
            <!-- 移动回拨6 -->
            <el-form-item v-if="copyFormData.beanName=='yhuatong'" label="行业类型：" prop="industry">
              <el-input v-model="copyFormData.industry" :disabled="copyFormData.status==1?true:false"
                placeholder="请输入行业类型" clearable></el-input>
            </el-form-item>
            <!-- 移动回拨7- -->
            <el-form-item v-if="copyFormData.beanName=='huaWeiGSHB'" label="accessKey:" prop="accessKey">
              <el-input v-model="copyFormData.accessKey" :disabled="copyFormData.status==1?true:false"
                placeholder="请输入行业类型" clearable></el-input>
            </el-form-item>
            <!-- 移动回拨7- -->
            <el-form-item v-if="copyFormData.beanName=='huaWeiGSHB'" label="secretKey:" prop="secretKey">
              <el-input v-model="copyFormData.secretKey" :disabled="copyFormData.status==1?true:false"
                placeholder="请输入用户密码" clearable></el-input>
            </el-form-item>
            <!-- 移动回拨7- -->
            <el-form-item v-if="copyFormData.beanName=='huaWeiGSHB'" label="appKey:" prop="appKey">
              <el-input v-model="copyFormData.appKey" :disabled="copyFormData.status==1?true:false"
                placeholder="请输入用户秘钥" clearable></el-input>
            </el-form-item>
            <!-- 025江苏回拨 -->
            <el-form-item v-if="copyFormData.beanName=='haoWaiHB'" label="appkey:" prop="appkey">
              <el-input v-model="copyFormData.appkey" :disabled="copyFormData.status==1?true:false"
                placeholder="请输入用户秘钥" clearable></el-input>
            </el-form-item>
            <!-- 025江苏回拨 -->
            <el-form-item v-if="copyFormData.beanName=='haoWaiHB'" label="appSecret:" prop="appSecret">
              <el-input v-model="copyFormData.appSecret" :disabled="copyFormData.status==1?true:false"
                placeholder="请输入用户密码" clearable></el-input>
            </el-form-item>
            <!-- 中国联通7- -->
            <el-form-item v-if="copyFormData.beanName=='jiuHuaHB'||copyFormData.beanName=='unicomAXB'" label="用户ID："
              prop="userId">
              <el-input v-model="copyFormData.userId" placeholder="请输入用户ID" clearable></el-input>
            </el-form-item>
            <!-- 中国联通7- -->
            <el-form-item v-if="copyFormData.beanName=='jiuHuaHB'||copyFormData.beanName=='unicomAXB'" label="秘钥："
              prop="secreteKey">
              <el-input v-model="copyFormData.secreteKey" placeholder="请输入秘钥" clearable></el-input>
            </el-form-item>
            <el-form-item label="接口设置：" prop="options">
              <el-input v-model="copyFormData.options" type="textarea" :autosize="{ minRows: 2, maxRows: 6 }" />
            </el-form-item>
            <el-form-item label="备注：">
              <el-input v-model="copyFormData.remark" type="textarea" :autosize="{ minRows: 2, maxRows: 3 }">
              </el-input>
            </el-form-item>
            <div v-if="copyFormData.beanName=='jiuHuaHB'||copyFormData.beanName=='unicomAXB'"
              :style="{color:'#E10602'}">说明:点击确认保存后,会将接口账户的价格,自动同步到所选渠道用户的价格设置中;若用户没有设置资费,会新添加山东回拨线路的资费,新添加的资费与接口账户的价格相同
            </div>
          </div>
          <template #footer>
            <el-form-item>
              <el-button class="cancel" plain @click="copyDialogVisible = false" round>取消</el-button>
              <el-button class="confirmAdd" @click="handleCopy('copyform')" round>确认</el-button>
            </el-form-item>
          </template>
        </el-dialog>
      </el-form>
    </div>
  </div>
</template>
<script>
import interfacets from '@/api/open/privacy/interface'
import price from '@/api/open/privacy/price'
import amountts from '@/api/open/privacy/amount'
import { message } from 'ant-design-vue'
import { ElMessageBox, ElMessage } from 'element-plus'
import { IconFont } from '@/utils/iconfont'
import userts from '@/api/web/user'
export default {
  name: 'Interface',
  components: {
    IconFont,
  },
  data() {
    const validatePrice = (rule, value, callback) => {
      let reg = /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/
      if (!value) {
        callback(new Error('金额不能为空'))
      } else if (!reg.test(value)) {
        callback(new Error('请输入正确的格式：整数或保留两位小数'))
      } else if (value.length > 10) {
        callback(new Error('最多可输入10个字符'))
      } else {
        callback()
      }
    }
    const validateUserId = (rule, value, callback) => {
      let reg = /^[0-9]\d*$/
      if (!value) {
        callback(new Error('用户ID不能为空'))
      } else if (!reg.test(value)) {
        callback(new Error('用户ID输入格式有误，请不要输入除数字外的其他字符'))
      } else {
        callback()
      }
    }
    const validatePsd = (rule, value, callback) => {
      let reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[a-zA-Z0-9]{1,50}$/
      if (!value) {
        callback(new Error('用户秘钥不能为空'))
      } else if (!reg.test(value)) {
        callback(new Error('用户秘钥格式只能为数字、英文组成'))
      } else {
        callback()
      }
    }
    return {
      copyDialogVisible: false, //复制模态框
      amount_id: [],
      amountMap: {},
      interface_id: [], // 拿到的接口类型
      tableData: [], //当前页面数据
      activeNames: [], //折叠面板
      centerDialogVisible: false, // 弹窗状态--号码操作
      centerPriceVisible: false, // 弹窗状态--接口价格
      debuggingPriceVisible: false,
      loading: true, // loding 状态启用
      interface_list: [], // 接口数组
      tableHeight: '', //表格高度
      valueTimeCreate: [], //选中时间段--创建时间
      defaultTime: [
        new Date(2000, 1, 1, 12, 0, 0),
        new Date(2000, 2, 1, 8, 0, 0),
      ], //默认时间 '12:00:00', '08:00:00'
      operationType: 0, //弹窗提交按钮文字状态
      currentPage: 1, // 分页
      currentStatus: true, // 分页状态
      interfaceIdTemp: '', // 储存临时变量
      total: 0, //总数据条数
      theQueryDialog: false,
      user_list: [], // 用户的集合
      copyFormData: {
        // 复制
        id: '',
        name: '', // 接口名称
        remark: '', // 备注
        options: '', // 接口配置
        uids: [], // 选择渠道用户
        userId: '', // 用户ID
        secreteKey: '', // 秘钥
        createTimeBegin: '', // 创建时间--开始
        createTimeEnd: '', // 创建时间--结束
        status: '', // 接口状态(0:正常 1:禁用)
        beanName: '', // 接口实现类名
        industry: '', // 行业(移动回拨6)
        accessKey: '', // 接受秘钥(移动回拨7)
        secretKey: '', // 密码(移动回拨7)
        appKey: '', // 秘钥id(移动回拨7)
        appkey: '', // 秘钥id(江苏回拨)
      },
      searchForm: {
        // 搜索项
        name: '', // 接口名称
        remark: '', // 备注
        options: '', // 接口配置
        uids: [], // 选择渠道用户
        userId: '', // 用户ID
        secreteKey: '', // 秘钥
        createTimeBegin: '', // 创建时间--开始
        createTimeEnd: '', // 创建时间--结束
        status: '', // 接口状态(0:正常 1:禁用)
        beanName: '', // 接口实现类名
        industry: '', // 行业(移动回拨6)
        accessKey: '', // 接受秘钥(移动回拨7)
        secretKey: '', // 密码(移动回拨7)
        appKey: '', // 秘钥id(移动回拨7)
        appkey: '', // 秘钥id(江苏回拨)
      },
      search_Form: {
        // 搜索项
        amountId: '',
        name: '', // 接口名称
        remark: '', // 备注
        options: '', // 接口配置
        createTimeBegin: '', // 创建时间--开始
        createTimeEnd: '', // 创建时间--结束
        status: '', // 接口状态(0:正常 1:禁用)
        beanName: '', // 接口实现类名
        page: 1,
        pageSize: 20,
      },
      price_info: {
        // 接口价格表单
        interfaceId: '', // 接口类型
        type: '', // 价格类型(0:月租 1:单计费 2:双计费)
        price: '', // 价格
        costPrice: '', // 成本价格
        status: '', // 状态(0:正常 1:禁用)
      },
      showRechargeAndDeduction: false, //充值or扣费
      RADdata: {
        type: 0,
        row: {},
      },
      amountUpform: {},
      rules: {
        industry: [
          {
            required: true,
            message: '输入不能为空',
            trigger: 'blur',
          },
        ],
        uids: [
          {
            required: true,
            message: '请选择渠道用户',
            trigger: 'change',
          },
        ],
        userId: [
          {
            required: true,
            message: '输入不能为空',
            trigger: 'blur',
          },
        ],
        accessKey: [
          {
            required: true,
            message: '输入不能为空',
            trigger: 'blur',
          },
        ],
        appKey: [
          {
            required: true,
            message: '输入不能为空',
            trigger: 'blur',
          },
        ],
        appkey: [
          {
            required: true,
            message: '输入不能为空',
            trigger: 'blur',
          },
        ],
        appSecret: [
          {
            required: true,
            message: '输入不能为空',
            trigger: 'blur',
          },
        ],
        secreteKey: [
          {
            required: true,
            message: '输入不能为空',
            trigger: 'blur',
          },
        ],
        name: [
          {
            required: true,
            message: '请填写接口名称',
            trigger: 'blur',
          },
        ],
        amountId: [
          {
            required: true,
            message: '请选择服务商',
            trigger: 'blur',
          },
        ],
        status: [
          {
            required: true,
            message: '请选择接口状态',
            trigger: 'blur',
          },
        ],
        beanName: [
          {
            required: true,
            message: '请填写接口实现类型',
            trigger: 'blur',
          },
        ],
        options: [
          {
            required: true,
            message: '请填写接口设置',
            trigger: 'blur',
          },
        ],
        type: [
          {
            required: true,
            message: '请设置价格类型',
            trigger: 'blur',
          },
        ],
        price: [
          {
            required: true,
            message: '请填写价格',
            trigger: 'blur',
          },
        ],
        costPrice: [
          {
            required: true,
            message: '请填写成本价格',
            trigger: 'blur',
          },
        ],
        amount: [{ required: true, trigger: 'blur', validator: validatePrice }],
      },
      debuggingForm: {},
      debuggingRow: '',
      debuggingloading: false,
    }
  },
  methods: {
    //复制确认
    handleCopy(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return false
        } else {
          //修改用户名和密码
          const options = JSON.parse(this.copyFormData.options)
          switch (this.copyFormData.beanName) {
            case 'jiuHuaHB':
              options.Username = this.copyFormData.userId
              options.Password = this.copyFormData.secreteKey
              break
            case 'unicomAXB':
              options.appKey = this.copyFormData.userId
              options.appSecret = this.copyFormData.secreteKey
              break
            case 'yhuatong':
              options.industry = this.copyFormData.industry
              break
            case 'huaWeiGSHB':
              options.accessKey = this.copyFormData.accessKey
              options.secretKey = this.copyFormData.secretKey
              options.appKey = this.copyFormData.appKey
              break
            case 'haoWaiHB':
              options.appSecret = this.copyFormData.appSecret
              options.appkey = this.copyFormData.appkey
              break
          }
          this.copyFormData.options = JSON.stringify(options)
          interfacets.copy(this.copyFormData).then((res) => {
            if (res.code !== 200) {
              return message.error(res.message)
            }
            message.success('修改成功')
            // this.copyFormData.uids = []
            this.copyFormData.secreteKey = ''
            this.copyFormData.userId = ''
            this.copyDialogVisible = false
            this.getDataList()
          })
        }
      })
    },
    // 删除接口价格
    deleteLabel(id) {
      price.deletePrice({ id }).then((res) => {
        if (res.code === 200) {
          message.success('删除成功')
          this.getDataList()
        }
      })
    },
    // 获取用户列表
    getUserList() {
      userts.queryList({}).then((res) => {
        this.user_list = res.data
      })
    },
    // 获取接口类型数组
    getApiType() {
      interfacets.seeApiList({}).then((res) => {
        this.interface_id = res.data
      })
    },
    // 接口类型数字转文字
    interfaceText(id) {
      for (let i of this.interface_id) {
        if (id === i.id) {
          return i.name
        }
      }
    },
    // typep匹配类型
    typeName(type) {
      let typeNameList = {
        0: '月租',
        1: '单计费',
        2: '双计费',
        3: '录音',
      }
      return typeNameList[type]
    },
    // 添加或修改
    addOrModify(type, form) {
      // type: 0=列表添加 1=列表修改 2=接口价格添加
      if (type === 0) {
        this.searchForm = {
          name: '',
          remark: '',
          options: '',
          createTimeBegin: '',
          createTimeEnd: '',
          status: '',
          beanName: '',
        }
        this.operationType = 0
        this.centerDialogVisible = true
      } else if (type === 1) {
        this.searchForm = JSON.parse(JSON.stringify(form))
        const options = JSON.parse(form.options)
        switch (this.searchForm.beanName) {
          case 'jiuHuaHB':
            options.Username = this.searchForm.userId
            options.Password = this.searchForm.secreteKey
            break
          case 'unicomAXB':
            options.appKey = this.searchForm.userId
            options.appSecret = this.searchForm.secreteKey
            break
          case 'yhuatong':
            options.industry = this.searchForm.industry
            break
          case 'huaWeiGSHB':
            options.accessKey = this.searchForm.accessKey
            options.secretKey = this.searchForm.secretKey
            options.appKey = this.searchForm.appKey
            break
          case 'haoWaiHB':
            options.appSecret = this.searchForm.appSecret
            options.appkey = this.searchForm.appkey
            break
        }
        this.operationType = 1
        this.centerDialogVisible = true
      } else if (type === 2) {
        this.operationType = 2
        this.price_info = {
          name: form.name,
          interfaceId: form.id,
          type: '',
          price: '',
          costPrice: '',
          status: '',
        }
        for (let i of this.interface_list) {
          if (i.name === form) {
            this.interfaceIdTemp = i.id
          }
        }
        this.centerPriceVisible = true
      } else if (type === 3) {
        this.price_info = form
        this.price_info.name = this.interfaceText(form.interfaceId)
        this.operationType = 3
        this.centerPriceVisible = true
      }
    },
    // 删除列表项
    confirmEvent(id) {
      interfacets.deleteInterface({ id }).then((res) => {
        if (res.code === 200) {
          message.success('删除成功')
          this.getDataList()
        }
      })
    },
    // 调试
    debuggingThis() {
      this.debuggingloading = true
      interfacets
        .debugging({ id: this.debuggingRow.id })
        .then((res) => {
          this.debuggingForm.message = res.data
          this.debuggingloading = false
        })
        .catch((err) => {
          this.debuggingForm.message = '上游线路异常，请多次尝试，请谨慎熔断！'
          this.debuggingloading = false
        })
    },
    // 熔断和恢复
    fusingOrRestore() {
      ElMessageBox.confirm('是否将接口恢复?', '接口调试', {
        cancelButtonText: '取消',
        confirmButtonText: '确认',
        type: 'warning',
        showClose: false,
        closeOnClickModal: false,
      })
        .then(() => {
          interfacets
            .isFuse({ id: this.debuggingRow.id, type: 0 })
            .then((res) => {
              ElMessage({
                type: 'success',
                message: '已恢复！',
              })
              this.debuggingRow.debugging =
                this.debuggingRow.debugging == 0 ? 1 : 0
            })
        })
        .catch(() => {})
      // ElMessageBox.confirm(
      //   this.debuggingRow.debugging == 0?'接口熔断后，该线路5分钟内无法使用！':'是否将接口恢复?',
      //   '接口调试',
      //   {
      //     cancelButtonText: '确认',
      //     confirmButtonText: '取消',
      //     type: 'warning',
      //     showClose:false,
      //     closeOnClickModal:false
      //   }
      // )
      //   .then(() => {

      //   })
      //   .catch(() => {
      //     interfacets.isFuse({ id:this.debuggingRow.id,type:this.debuggingRow.debugging == 0?1:0 }).then((res) => {
      //       ElMessage({
      //         type: 'success',
      //         message: this.debuggingRow.debugging == 0?'已熔断！':'已恢复！',
      //       })
      //       this.debuggingRow.debugging = this.debuggingRow.debugging == 0?1:0
      //     });
      //   })
    },
    // 调试
    debugging(row) {
      ElMessageBox.confirm('是否进行接口调试?', '接口调试', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
        showClose: false,
        closeOnClickModal: false,
      })
        .then(() => {
          this.debuggingPriceVisible = true
          this.debuggingloading = true
          this.debuggingRow = row
          interfacets
            .debugging({ id: row.id })
            .then((res) => {
              this.debuggingForm.message = res.data
              this.debuggingloading = false
            })
            .catch((err) => {
              this.debuggingForm.message =
                '上游线路异常，请多次尝试，请谨慎熔断！'
              this.debuggingloading = false
            })
          // ElMessage({
          //   type: 'success',
          //   message: '正在连接中...',
          // })
        })
        .catch(() => {})
    },
    // 表单提交
    formSubmit(type, formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // type: 0=列表添加 1=列表修改 2=接口价格添加
          if (type === 0) {
            interfacets.addInterface({ ...this.searchForm }).then((res) => {
              if (res.code === 200) {
                this.getDataList()
                message.success('添加成功')
              }
            })
          } else if (type === 1) {
            //修改用户名和密码
            const options = JSON.parse(this.searchForm.options)
            switch (this.searchForm.beanName) {
              case 'jiuHuaHB':
                options.Username = this.searchForm.userId
                options.Password = this.searchForm.secreteKey
                break
              case 'unicomAXB':
                options.appKey = this.searchForm.userId
                options.appSecret = this.searchForm.secreteKey
                break
              case 'yhuatong':
                options.industry = this.searchForm.industry
                break
              case 'huaWeiGSHB':
                options.accessKey = this.searchForm.accessKey
                options.secretKey = this.searchForm.secretKey
                options.appKey = this.searchForm.appKey
                break
              case 'haoWaiHB':
                options.appSecret = this.searchForm.appSecret
                options.appkey = this.searchForm.appkey
                break
            }
            this.searchForm.options = JSON.stringify(options)
            interfacets.editInterface({ ...this.searchForm }).then((res) => {
              if (res.code !== 200) {
                return message.success(res.message)
              }
              this.searchForm = {}
              this.getDataList()
              message.success('修改成功')
            })
          } else if (type === 2) {
            // this.price_info.interfaceId = this.interfaceIdTemp;
            price.addPrice({ ...this.price_info }).then((res) => {
              if (res.code === 200) {
                this.getDataList()
                message.success('添加成功')
              }
            })
          } else if (type === 3) {
            price.editPrice({ ...this.price_info }).then((res) => {
              if (res.code === 200) {
                this.getDataList()
                message.success('修改成功')
              }
            })
          }
          this.centerDialogVisible = false
          this.centerPriceVisible = false
        } else {
          return false
        }
      })
    },
    // 复制
    copyAndDeduction(scope) {
      Object.assign(this.copyFormData, scope)
      this.copyDialogVisible = true
    },
    // 充值、扣费
    rechargeAndDeduction(type, row) {
      this.amountUpform.amount = ''
      this.RADdata.type = type
      this.RADdata.row = row
      this.showRechargeAndDeduction = true
    },
    // 充值、扣费取消
    resetForm(formName) {
      this.$refs[formName].resetFields()
      this.showRechargeAndDeduction = false
      this.amountUpform.amount = ''
    },
    // 确认充值
    addBalance(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          price
            .addBalance({
              amount: this.amountUpform.amount,
              interfaceId: this.RADdata.row.id,
            })
            .then((res) => {
              this.$message.success('充值成功！!')
              this.getDataList()
              this.showRechargeAndDeduction = false
            })
        } else {
          // console.log('error submit!!');
          return false
        }
      })
    },
    // 确认扣费
    decBalance(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          price
            .decBalance({
              amount: this.amountUpform.amount,
              interfaceId: this.RADdata.row.id,
            })
            .then((res) => {
              this.$message.success('扣费成功!')
              this.getDataList()
              this.showRechargeAndDeduction = false
            })
        } else {
          // console.log('error submit!!');
          return false
        }
      })
    },
    // 重置搜索项
    searchTapReset(kind) {
      this.search_Form = {
        page: this.currentPage,
        pageSize: 20,
        status: '',
      }
      this.valueTimeCreate = []
      if (kind == 1) {
        this.valueTimeCreate = []
        this.searchTapFun()
      }
    },
    // 搜索特殊项
    searchTapFun() {
      if (this.valueTimeCreate) {
        this.search_Form.createTimeBegin = this.valueTimeCreate[0]
        this.search_Form.createTimeEnd = this.valueTimeCreate[1]
      } else {
        this.search_Form.createTimeBegin = ''
        this.search_Form.createTimeEnd = ''
      }
      this.getDataList()
      this.theQueryDialog = false
    },
    // JSON转换
    evalJSON(item) {
      if (item.options) {
        return JSON.parse(item.options)
      }
    },
    // 分页--条数
    handleSizeChange(val) {
      this.search_Form.pageSize = val
      this.getDataList()
    },
    // 分页--页码
    handleCurrentChange(val) {
      this.search_Form.page = val
      this.currentPage = val
      this.getDataList()
    },
    // 获取接口数组
    getApiList() {
      interfacets.seeApiList({}).then((res) => {
        this.interface_list = res.data
      })
    },
    // 获取数据列表
    getDataList() {
      this.loading = true
      interfacets.apiRunList({ ...this.search_Form }).then((res) => {
        this.tableData = res.data.records
        this.total = res.data.total
        this.loading = false
      })
      amountts.getAmountList({}).then((res) => {
        
        this.amount_id = res.data
        res.data.forEach((item, i) => {
          this.amountMap[item.id] = item.name
        })
      })
    },
  },
  created() {
    this.getUserList()
    this.getDataList()
    this.getApiList()
    this.getApiType()
    this.tableHeight = this.$store.state.tableHeight
  },
}
</script>
<style lang="scss" scoped>
.Interface {
  height: calc(100vh - 135px);
  overflow: overlay;
  padding-right: 1.1rem;
  box-sizing: content-box;
  .title-name {
    margin-bottom: 1rem;
    font-size: 0.9375rem;
    font-weight: 600;
    color: #303133;
  }
  .title-button {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    padding-right: 1rem;
    // background-color:orange;
  }
  .screening {
    margin-bottom: 0.9375rem;
    .screening-out {
      margin: 0 0.9375rem;
    }
    .title-button {
      padding-right: 0;
    }
  }
  .demo-table-expand {
    font-size: 0;
  }
  .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
  }
  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }
}
.confirmAdd {
  background: #637dff;
  color: #fff;
}
.title-buttom {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
}
.pass {
  color: #67c23a;
}
.unpass {
  color: #f56c6c;
}
.button-width {
  width: 80px;
  text-align: center;
}
</style>